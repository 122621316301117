import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import theme from "../theme";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby";

// Equivalent of CSS
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "95vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
});

export default function NotFoundPage(): JSX.Element {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Arms General Contractor - Page Not Found</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className={classes.root}>
        <Typography component="h1" variant="h3" gutterBottom>
          That Page does not Exist
        </Typography>
        <Link to="/">
          <Button color="primary" variant="contained" size="large">
            Go Back Home
          </Button>
        </Link>
      </div>
    </ThemeProvider>
  );
}
